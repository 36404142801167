<template>

  <!-- <Watch /> -->

  <GalleryGrid />
</template>

<script>
// import Watch from "@/views/Watch.vue";
import GalleryGrid from "@/components/GalleryGrid.vue";
// import Image from "@/components/Image.vue";

export default {
  name: "Gallery",
  components: {
    // Watch,
    // Image,
    GalleryGrid,
  },

  setup() {
  },
};
</script>

<style lang="scss" scoped>
</style>
